export const PFCurrentStage4Side1 = [
  {
    char_one: 'Argenti',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 2.23,
    rank: 1,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 2.18,
    rank: 2,
    avg_round: 38838,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 1.82,
    rank: 3,
    avg_round: 38919,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 1.68,
    rank: 4,
    avg_round: 29709,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 1.55,
    rank: 5,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 1.37,
    rank: 6,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 1.36,
    rank: 7,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 1.34,
    rank: 8,
    avg_round: 39932,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 1.27,
    rank: 9,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 1.23,
    rank: 10,
    avg_round: 39669,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'fu-xuan',
    app_rate: 1.14,
    rank: 11,
    avg_round: 37197,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 1.07,
    rank: 12,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.94,
    rank: 13,
    avg_round: 39904,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.92,
    rank: 14,
    avg_round: 38150,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.89,
    rank: 15,
    avg_round: 34466,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.81,
    rank: 16,
    avg_round: 36149,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.76,
    rank: 17,
    avg_round: 36572,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.73,
    rank: 18,
    avg_round: 39709,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.73,
    rank: 18,
    avg_round: 33374,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Huohuo',
    app_rate: 0.67,
    rank: 20,
    avg_round: 35109,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'ruan-mei',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.64,
    rank: 21,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.62,
    rank: 22,
    avg_round: 38218,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.62,
    rank: 22,
    avg_round: 39543,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Herta',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.62,
    rank: 22,
    avg_round: 36572,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.6,
    rank: 25,
    avg_round: 35932,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.6,
    rank: 25,
    avg_round: 36687,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.6,
    rank: 25,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Aventurine',
    app_rate: 0.58,
    rank: 28,
    avg_round: 36807,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.54,
    rank: 29,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.54,
    rank: 29,
    avg_round: 38562,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.53,
    rank: 31,
    avg_round: 32063,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.53,
    rank: 31,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.52,
    rank: 33,
    avg_round: 38377,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.52,
    rank: 33,
    avg_round: 36518,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.51,
    rank: 35,
    avg_round: 39676,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.48,
    rank: 36,
    avg_round: 36533,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.48,
    rank: 36,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.47,
    rank: 38,
    avg_round: 36778,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Blade',
    char_three: 'Bronya',
    char_four: 'Luocha',
    app_rate: 0.47,
    rank: 38,
    avg_round: 37987,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.47,
    rank: 38,
    avg_round: 39191,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Lynx',
    app_rate: 0.46,
    rank: 41,
    avg_round: 35381,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 0.45,
    rank: 42,
    avg_round: 39800,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Bronya',
    char_three: 'Tingyun',
    char_four: 'Luocha',
    app_rate: 0.44,
    rank: 43,
    avg_round: 39034,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.42,
    rank: 44,
    avg_round: 35272,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.42,
    rank: 44,
    avg_round: 36982,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'ruan-mei',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.41,
    rank: 46,
    avg_round: 39320,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.41,
    rank: 46,
    avg_round: 33676,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.41,
    rank: 46,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.4,
    rank: 49,
    avg_round: 38980,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.4,
    rank: 49,
    avg_round: 37857,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.39,
    rank: 51,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Luocha',
    app_rate: 0.38,
    rank: 52,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.38,
    rank: 52,
    avg_round: 36805,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Argenti',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.37,
    rank: 54,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.36,
    rank: 55,
    avg_round: 34374,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.36,
    rank: 55,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.36,
    rank: 55,
    avg_round: 38918,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.35,
    rank: 58,
    avg_round: 37562,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.33,
    rank: 59,
    avg_round: 32254,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Herta',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.32,
    rank: 60,
    avg_round: 37132,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.32,
    rank: 60,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Bronya',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.31,
    rank: 62,
    avg_round: 39650,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Herta',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.31,
    rank: 62,
    avg_round: 38896,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.3,
    rank: 64,
    avg_round: 36732,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.29,
    rank: 65,
    avg_round: 38826,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.28,
    rank: 66,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.27,
    rank: 67,
    avg_round: 36933,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.27,
    rank: 67,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.26,
    rank: 69,
    avg_round: 36718,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Bronya',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.26,
    rank: 69,
    avg_round: 39667,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Argenti',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.26,
    rank: 69,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Luocha',
    app_rate: 0.25,
    rank: 72,
    avg_round: 38495,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.25,
    rank: 72,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.25,
    rank: 72,
    avg_round: 32552,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Gepard',
    app_rate: 0.25,
    rank: 72,
    avg_round: 36156,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Herta',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.25,
    rank: 72,
    avg_round: 35647,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.25,
    rank: 72,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.24,
    rank: 78,
    avg_round: 34465,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.24,
    rank: 78,
    avg_round: 32209,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.24,
    rank: 78,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Herta',
    char_three: 'trailblazer-imaginary',
    char_four: 'ruan-mei',
    app_rate: 0.23,
    rank: 81,
    avg_round: 31699,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.23,
    rank: 81,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Lynx',
    app_rate: 0.23,
    rank: 81,
    avg_round: 35810,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.23,
    rank: 81,
    avg_round: 34761,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Argenti',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.23,
    rank: 81,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'Lynx',
    app_rate: 0.22,
    rank: 86,
    avg_round: 39600,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Luocha',
    app_rate: 0.22,
    rank: 86,
    avg_round: 38401,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 0.22,
    rank: 86,
    avg_round: 34726,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'ruan-mei',
    char_three: 'Tingyun',
    char_four: 'Luocha',
    app_rate: 0.22,
    rank: 86,
    avg_round: 39617,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.21,
    rank: 90,
    avg_round: 38833,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Argenti',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.21,
    rank: 90,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Lynx',
    app_rate: 0.21,
    rank: 90,
    avg_round: 31421,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.21,
    rank: 90,
    avg_round: 33324,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Lynx',
    app_rate: 0.21,
    rank: 90,
    avg_round: 37585,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Lynx',
    app_rate: 0.21,
    rank: 90,
    avg_round: 37474,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.2,
    rank: 96,
    avg_round: 39650,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Clara',
    char_three: 'Herta',
    char_four: 'fu-xuan',
    app_rate: 0.2,
    rank: 96,
    avg_round: 34283,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.2,
    rank: 96,
    avg_round: 33909,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.2,
    rank: 96,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.2,
    rank: 96,
    avg_round: 35516,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.2,
    rank: 96,
    avg_round: 34407,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Gallagher',
    app_rate: 0.19,
    rank: 102,
    avg_round: 34956,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Yunli',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.19,
    rank: 102,
    avg_round: 37880,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Hanya',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.19,
    rank: 102,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.19,
    rank: 102,
    avg_round: 33977,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Clara',
    char_three: 'Herta',
    char_four: 'Aventurine',
    app_rate: 0.19,
    rank: 102,
    avg_round: 39043,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Argenti',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.19,
    rank: 102,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Luocha',
    app_rate: 0.19,
    rank: 102,
    avg_round: 34074,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'Gepard',
    app_rate: 0.19,
    rank: 102,
    avg_round: 32804,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.18,
    rank: 110,
    avg_round: 35202,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'ruan-mei',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.18,
    rank: 110,
    avg_round: 36007,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.18,
    rank: 110,
    avg_round: 32448,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Blade',
    char_three: 'Bronya',
    char_four: 'fu-xuan',
    app_rate: 0.18,
    rank: 110,
    avg_round: 36077,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.17,
    rank: 114,
    avg_round: 34731,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.17,
    rank: 114,
    avg_round: 37906,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Clara',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.17,
    rank: 114,
    avg_round: 39100,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Clara',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.17,
    rank: 114,
    avg_round: 34296,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.17,
    rank: 114,
    avg_round: 32341,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.17,
    rank: 114,
    avg_round: 32307,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Hanya',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.17,
    rank: 114,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Argenti',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.16,
    rank: 121,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Luocha',
    app_rate: 0.16,
    rank: 121,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'Herta',
    char_four: 'fu-xuan',
    app_rate: 0.16,
    rank: 121,
    avg_round: 35750,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.16,
    rank: 121,
    avg_round: 39358,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.16,
    rank: 121,
    avg_round: 35350,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.16,
    rank: 121,
    avg_round: 37035,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.16,
    rank: 121,
    avg_round: 35503,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Herta',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.16,
    rank: 121,
    avg_round: 36561,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.16,
    rank: 121,
    avg_round: 34971,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Herta',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.16,
    rank: 121,
    avg_round: 35459,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Tingyun',
    app_rate: 0.15,
    rank: 131,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Bronya',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.15,
    rank: 131,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.15,
    rank: 131,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Tingyun',
    app_rate: 0.15,
    rank: 131,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.15,
    rank: 131,
    avg_round: 37012,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.15,
    rank: 131,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Blade',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.15,
    rank: 131,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.15,
    rank: 131,
    avg_round: 31779,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Blade',
    char_three: 'Bronya',
    char_four: 'Huohuo',
    app_rate: 0.15,
    rank: 131,
    avg_round: 37151,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Herta',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.15,
    rank: 131,
    avg_round: 37062,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Luocha',
    app_rate: 0.14,
    rank: 141,
    avg_round: 39193,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.14,
    rank: 141,
    avg_round: 38350,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Himeko',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.14,
    rank: 141,
    avg_round: 29952,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Herta',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.14,
    rank: 141,
    avg_round: 33538,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Clara',
    char_three: 'Herta',
    char_four: 'fu-xuan',
    app_rate: 0.14,
    rank: 141,
    avg_round: 37046,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Luocha',
    app_rate: 0.14,
    rank: 141,
    avg_round: 37165,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.14,
    rank: 141,
    avg_round: 36825,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.14,
    rank: 141,
    avg_round: 35338,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Herta',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 0.14,
    rank: 141,
    avg_round: 36137,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Herta',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.14,
    rank: 141,
    avg_round: 36667,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.14,
    rank: 141,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.14,
    rank: 141,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Jade',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.13,
    rank: 153,
    avg_round: 30606,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.13,
    rank: 153,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 0.13,
    rank: 153,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Bronya',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.13,
    rank: 153,
    avg_round: 37536,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Clara',
    char_three: 'Herta',
    char_four: 'Huohuo',
    app_rate: 0.13,
    rank: 153,
    avg_round: 36163,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.13,
    rank: 153,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Argenti',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.13,
    rank: 153,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'ruan-mei',
    app_rate: 0.13,
    rank: 153,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.13,
    rank: 153,
    avg_round: 30425,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Argenti',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.13,
    rank: 153,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'Herta',
    char_four: 'Aventurine',
    app_rate: 0.13,
    rank: 153,
    avg_round: 38622,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Herta',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.12,
    rank: 164,
    avg_round: 31717,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.12,
    rank: 164,
    avg_round: 32465,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'ruan-mei',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.12,
    rank: 164,
    avg_round: 34318,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Clara',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.12,
    rank: 164,
    avg_round: 35577,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.12,
    rank: 164,
    avg_round: 38871,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.12,
    rank: 164,
    avg_round: 38362,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.12,
    rank: 164,
    avg_round: 39264,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.12,
    rank: 164,
    avg_round: 31852,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Gallagher',
    app_rate: 0.1,
    rank: 172,
    avg_round: 39320,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 0.1,
    rank: 172,
    avg_round: 34467,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.1,
    rank: 172,
    avg_round: 37341,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Yunli',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.1,
    rank: 172,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Yunli',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.1,
    rank: 172,
    avg_round: 37375,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Robin',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.1,
    rank: 172,
    avg_round: 35907,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.1,
    rank: 172,
    avg_round: 37978,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Clara',
    char_three: 'Herta',
    char_four: 'Robin',
    app_rate: 0.1,
    rank: 172,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.1,
    rank: 172,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Bronya',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.1,
    rank: 172,
    avg_round: 32251,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.1,
    rank: 172,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Robin',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.1,
    rank: 172,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.1,
    rank: 172,
    avg_round: 35788,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Robin',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.1,
    rank: 172,
    avg_round: 36088,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Sparkle',
    app_rate: 0.1,
    rank: 172,
    avg_round: 37380,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Blade',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.1,
    rank: 172,
    avg_round: 38917,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.1,
    rank: 172,
    avg_round: 37200,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.1,
    rank: 172,
    avg_round: 38610,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Argenti',
    char_three: 'Tingyun',
    char_four: 'Luocha',
    app_rate: 0.1,
    rank: 172,
    avg_round: 39380,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Clara',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.09,
    rank: 191,
    avg_round: 36190,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Argenti',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.09,
    rank: 191,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.09,
    rank: 191,
    avg_round: 38883,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.09,
    rank: 191,
    avg_round: 37440,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Bronya',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.09,
    rank: 191,
    avg_round: 37757,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Clara',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.09,
    rank: 191,
    avg_round: 36094,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.09,
    rank: 191,
    avg_round: 31598,
    star_num: '4'
  },
  {
    char_one: 'Xueyi',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.09,
    rank: 191,
    avg_round: 31182,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.09,
    rank: 191,
    avg_round: 36953,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Clara',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.09,
    rank: 191,
    avg_round: 36634,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.09,
    rank: 191,
    avg_round: 36208,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Yunli',
    char_three: 'Herta',
    char_four: 'Robin',
    app_rate: 0.09,
    rank: 191,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Himeko',
    char_three: 'Herta',
    char_four: 'ruan-mei',
    app_rate: 0.09,
    rank: 191,
    avg_round: 27390,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Blade',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.09,
    rank: 191,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Bronya',
    char_three: 'Sparkle',
    char_four: 'Tingyun',
    app_rate: 0.09,
    rank: 191,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Bailu',
    app_rate: 0.09,
    rank: 191,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.09,
    rank: 191,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'Herta',
    char_four: 'ruan-mei',
    app_rate: 0.09,
    rank: 191,
    avg_round: 30584,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Herta',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.09,
    rank: 191,
    avg_round: 37536,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'Herta',
    char_four: 'Huohuo',
    app_rate: 0.09,
    rank: 191,
    avg_round: 35385,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.09,
    rank: 191,
    avg_round: 36700,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.09,
    rank: 191,
    avg_round: 36563,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Gallagher',
    app_rate: 0.09,
    rank: 191,
    avg_round: 38093,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.09,
    rank: 191,
    avg_round: 31725,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.09,
    rank: 191,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'ruan-mei',
    app_rate: 0.09,
    rank: 191,
    avg_round: 36548,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'silver-wolf',
    char_four: 'Aventurine',
    app_rate: 0.09,
    rank: 191,
    avg_round: 30853,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Herta',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.08,
    rank: 218,
    avg_round: 36436,
    star_num: '4'
  },
  {
    char_one: 'Xueyi',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.08,
    rank: 218,
    avg_round: 34838,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Argenti',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.08,
    rank: 218,
    avg_round: 37425,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Herta',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.08,
    rank: 218,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Lynx',
    app_rate: 0.08,
    rank: 218,
    avg_round: 35220,
    star_num: '4'
  },
  {
    char_one: 'Blade',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.08,
    rank: 218,
    avg_round: 34988,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Himeko',
    char_three: 'trailblazer-imaginary',
    char_four: 'ruan-mei',
    app_rate: 0.08,
    rank: 218,
    avg_round: 31087,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Blade',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.08,
    rank: 218,
    avg_round: 35703,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.08,
    rank: 218,
    avg_round: 30442,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.08,
    rank: 218,
    avg_round: 31872,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Tingyun',
    app_rate: 0.08,
    rank: 218,
    avg_round: 37017,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Clara',
    char_three: 'Herta',
    char_four: 'Luocha',
    app_rate: 0.08,
    rank: 218,
    avg_round: 36796,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Herta',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.08,
    rank: 218,
    avg_round: 37853,
    star_num: '4'
  },
  {
    char_one: 'Blade',
    char_two: 'Bronya',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.08,
    rank: 218,
    avg_round: 34452,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Blade',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.08,
    rank: 218,
    avg_round: 35100,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.08,
    rank: 218,
    avg_round: 32400,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Herta',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.08,
    rank: 218,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Bronya',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.08,
    rank: 218,
    avg_round: 32530,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.08,
    rank: 218,
    avg_round: 37244,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Herta',
    char_three: 'trailblazer-imaginary',
    char_four: 'Gallagher',
    app_rate: 0.08,
    rank: 218,
    avg_round: 29406,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Clara',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.08,
    rank: 218,
    avg_round: 35620,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Yunli',
    char_three: 'Herta',
    char_four: 'fu-xuan',
    app_rate: 0.08,
    rank: 218,
    avg_round: 38770,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 0.08,
    rank: 218,
    avg_round: 37411,
    star_num: '4'
  },
  {
    char_one: 'Xueyi',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.08,
    rank: 218,
    avg_round: 34948,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.08,
    rank: 218,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Yunli',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.07,
    rank: 243,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.07,
    rank: 243,
    avg_round: 33883,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.07,
    rank: 243,
    avg_round: 39000,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Gepard',
    app_rate: 0.07,
    rank: 243,
    avg_round: 35983,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Hanya',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.07,
    rank: 243,
    avg_round: 38349,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.07,
    rank: 243,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.07,
    rank: 243,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Blade',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.07,
    rank: 243,
    avg_round: 32775,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'ruan-mei',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.07,
    rank: 243,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.07,
    rank: 243,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Herta',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.07,
    rank: 243,
    avg_round: 35036,
    star_num: '4'
  },
  {
    char_one: 'Xueyi',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.07,
    rank: 243,
    avg_round: 32184,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Bronya',
    char_three: 'Tingyun',
    char_four: 'Gallagher',
    app_rate: 0.07,
    rank: 243,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Bronya',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.07,
    rank: 243,
    avg_round: 36776,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.07,
    rank: 243,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Luocha',
    app_rate: 0.07,
    rank: 243,
    avg_round: 37896,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Hanya',
    char_three: 'Tingyun',
    char_four: 'Luocha',
    app_rate: 0.07,
    rank: 243,
    avg_round: 38875,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Sparkle',
    app_rate: 0.07,
    rank: 243,
    avg_round: 31890,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.07,
    rank: 243,
    avg_round: 36124,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'march-7th-swordmaster',
    char_four: 'Huohuo',
    app_rate: 0.07,
    rank: 243,
    avg_round: 38250,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.07,
    rank: 243,
    avg_round: 34144,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Luocha',
    app_rate: 0.07,
    rank: 243,
    avg_round: 33273,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Herta',
    char_three: 'Sparkle',
    char_four: 'Lynx',
    app_rate: 0.07,
    rank: 243,
    avg_round: 33579,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.07,
    rank: 243,
    avg_round: 37369,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Clara',
    char_three: 'Herta',
    char_four: 'Aventurine',
    app_rate: 0.07,
    rank: 243,
    avg_round: 34638,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.07,
    rank: 243,
    avg_round: 32058,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'ruan-mei',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 269,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'Sparkle',
    app_rate: 0.06,
    rank: 269,
    avg_round: 33132,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.06,
    rank: 269,
    avg_round: 32580,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Bronya',
    char_three: 'Sparkle',
    char_four: 'Tingyun',
    app_rate: 0.06,
    rank: 269,
    avg_round: 27944,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 269,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Gallagher',
    app_rate: 0.06,
    rank: 269,
    avg_round: 38126,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 269,
    avg_round: 37397,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Tingyun',
    app_rate: 0.06,
    rank: 269,
    avg_round: 38800,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 269,
    avg_round: 32156,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Blade',
    char_three: 'Bronya',
    char_four: 'ruan-mei',
    app_rate: 0.06,
    rank: 269,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Yunli',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 269,
    avg_round: 35786,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Lynx',
    app_rate: 0.06,
    rank: 269,
    avg_round: 37074,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'silver-wolf',
    char_four: 'Gepard',
    app_rate: 0.06,
    rank: 269,
    avg_round: 35000,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 269,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 269,
    avg_round: 30650,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'Tingyun',
    char_four: 'Lynx',
    app_rate: 0.06,
    rank: 269,
    avg_round: 30492,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Tingyun',
    app_rate: 0.06,
    rank: 269,
    avg_round: 34996,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 269,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.06,
    rank: 269,
    avg_round: 37950,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 269,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Yunli',
    char_three: 'Herta',
    char_four: 'Huohuo',
    app_rate: 0.06,
    rank: 269,
    avg_round: 37959,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Blade',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 269,
    avg_round: 31427,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Blade',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.06,
    rank: 269,
    avg_round: 24338,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.06,
    rank: 269,
    avg_round: 37328,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.06,
    rank: 269,
    avg_round: 34017,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Luocha',
    app_rate: 0.06,
    rank: 269,
    avg_round: 38167,
    star_num: '4'
  },
  {
    char_one: 'Blade',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.06,
    rank: 269,
    avg_round: 37500,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Argenti',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.06,
    rank: 269,
    avg_round: 37533,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.06,
    rank: 269,
    avg_round: 39188,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Argenti',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.06,
    rank: 269,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.06,
    rank: 269,
    avg_round: 34735,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Clara',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 269,
    avg_round: 30168,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.06,
    rank: 269,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Bronya',
    char_four: 'Aventurine',
    app_rate: 0.06,
    rank: 269,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.06,
    rank: 269,
    avg_round: 34054,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Herta',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.06,
    rank: 269,
    avg_round: 36691,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 305,
    avg_round: 36776,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Sparkle',
    app_rate: 0.05,
    rank: 305,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Blade',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 305,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.05,
    rank: 305,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Blade',
    char_three: 'Herta',
    char_four: 'ruan-mei',
    app_rate: 0.05,
    rank: 305,
    avg_round: 35627,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'ruan-mei',
    char_three: 'Tingyun',
    char_four: 'Luocha',
    app_rate: 0.05,
    rank: 305,
    avg_round: 33673,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'ruan-mei',
    app_rate: 0.05,
    rank: 305,
    avg_round: 31035,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.05,
    rank: 305,
    avg_round: 31583,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Argenti',
    char_three: 'Bronya',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 305,
    avg_round: 37334,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Herta',
    char_three: 'Sparkle',
    char_four: 'Lynx',
    app_rate: 0.05,
    rank: 305,
    avg_round: 34606,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Sparkle',
    app_rate: 0.05,
    rank: 305,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.05,
    rank: 305,
    avg_round: 37603,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Herta',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.05,
    rank: 305,
    avg_round: 32478,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Blade',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.05,
    rank: 305,
    avg_round: 35749,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Tingyun',
    app_rate: 0.05,
    rank: 305,
    avg_round: 34524,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 305,
    avg_round: 26047,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Clara',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.05,
    rank: 305,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'ruan-mei',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.05,
    rank: 305,
    avg_round: 34407,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.05,
    rank: 305,
    avg_round: 32350,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'ruan-mei',
    char_three: 'Tingyun',
    char_four: 'Lynx',
    app_rate: 0.05,
    rank: 305,
    avg_round: 30609,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Lynx',
    app_rate: 0.05,
    rank: 305,
    avg_round: 37162,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.05,
    rank: 305,
    avg_round: 30553,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Clara',
    char_three: 'Herta',
    char_four: 'ruan-mei',
    app_rate: 0.05,
    rank: 305,
    avg_round: 27560,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Argenti',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.05,
    rank: 305,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Bronya',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.05,
    rank: 305,
    avg_round: 34045,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Gallagher',
    app_rate: 0.05,
    rank: 305,
    avg_round: 37950,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.05,
    rank: 305,
    avg_round: 36708,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'march-7th-swordmaster',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 305,
    avg_round: 30943,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.05,
    rank: 305,
    avg_round: 32864,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'ruan-mei',
    app_rate: 0.05,
    rank: 305,
    avg_round: 34045,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Lynx',
    app_rate: 0.05,
    rank: 305,
    avg_round: 32282,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Blade',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.05,
    rank: 305,
    avg_round: 30680,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Bronya',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 305,
    avg_round: 38200,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 305,
    avg_round: 33068,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Argenti',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.05,
    rank: 305,
    avg_round: 38430,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Tingyun',
    app_rate: 0.05,
    rank: 305,
    avg_round: 34594,
    star_num: '4'
  },
  {
    char_one: 'Qingque',
    char_two: 'Herta',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 305,
    avg_round: 32066,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.05,
    rank: 305,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Yunli',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.05,
    rank: 305,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.05,
    rank: 305,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Herta',
    char_three: 'Tingyun',
    char_four: 'Luocha',
    app_rate: 0.05,
    rank: 305,
    avg_round: 35462,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 305,
    avg_round: 37460,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Lynx',
    app_rate: 0.05,
    rank: 305,
    avg_round: 35800,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.05,
    rank: 305,
    avg_round: 29785,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Pela',
    char_four: 'Gepard',
    app_rate: 0.05,
    rank: 305,
    avg_round: 38226,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.05,
    rank: 305,
    avg_round: 33008,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Blade',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.05,
    rank: 305,
    avg_round: 32696,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 305,
    avg_round: 32988,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.05,
    rank: 305,
    avg_round: 31545,
    star_num: '4'
  },
  {
    char_one: 'Blade',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 305,
    avg_round: 35082,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Blade',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.05,
    rank: 305,
    avg_round: 36972,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'Herta',
    char_four: 'Robin',
    app_rate: 0.05,
    rank: 305,
    avg_round: 37045,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Lynx',
    app_rate: 0.05,
    rank: 305,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Bronya',
    char_three: 'Hanya',
    char_four: 'Luocha',
    app_rate: 0.05,
    rank: 305,
    avg_round: 37600,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Blade',
    char_three: 'Sparkle',
    char_four: 'Lynx',
    app_rate: 0.05,
    rank: 305,
    avg_round: 37012,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Gallagher',
    app_rate: 0.05,
    rank: 305,
    avg_round: 33203,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.05,
    rank: 305,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.05,
    rank: 305,
    avg_round: 31314,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Blade',
    char_three: 'Bronya',
    char_four: 'Lynx',
    app_rate: 0.05,
    rank: 305,
    avg_round: 37596,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Bronya',
    char_four: 'Luocha',
    app_rate: 0.05,
    rank: 305,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 305,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.05,
    rank: 305,
    avg_round: 33597,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Yunli',
    char_three: 'Herta',
    char_four: 'Huohuo',
    app_rate: 0.04,
    rank: 367,
    avg_round: 35272,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 367,
    avg_round: 31925,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'Lynx',
    app_rate: 0.04,
    rank: 367,
    avg_round: 30736,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 367,
    avg_round: 35600,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Tingyun',
    app_rate: 0.04,
    rank: 367,
    avg_round: 33535,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'ruan-mei',
    char_three: 'Tingyun',
    char_four: 'Gallagher',
    app_rate: 0.04,
    rank: 367,
    avg_round: 35180,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'march-7th',
    app_rate: 0.04,
    rank: 367,
    avg_round: 33029,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 367,
    avg_round: 37000,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 367,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Bronya',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.04,
    rank: 367,
    avg_round: 33310,
    star_num: '4'
  },
  {
    char_one: 'Blade',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.04,
    rank: 367,
    avg_round: 37015,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Clara',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.04,
    rank: 367,
    avg_round: 33628,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Yunli',
    char_three: 'Herta',
    char_four: 'Lynx',
    app_rate: 0.04,
    rank: 367,
    avg_round: 37400,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Blade',
    char_three: 'Bronya',
    char_four: 'Gallagher',
    app_rate: 0.04,
    rank: 367,
    avg_round: 36535,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Yunli',
    char_three: 'Sparkle',
    char_four: 'Lynx',
    app_rate: 0.04,
    rank: 367,
    avg_round: 38883,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Clara',
    char_three: 'Herta',
    char_four: 'Huohuo',
    app_rate: 0.04,
    rank: 367,
    avg_round: 31195,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.04,
    rank: 367,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Herta',
    char_three: 'march-7th-swordmaster',
    char_four: 'Luocha',
    app_rate: 0.04,
    rank: 367,
    avg_round: 34649,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Yunli',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 367,
    avg_round: 35450,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 367,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Pela',
    char_four: 'Gepard',
    app_rate: 0.04,
    rank: 367,
    avg_round: 32717,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Herta',
    char_three: 'Tingyun',
    char_four: 'Lynx',
    app_rate: 0.04,
    rank: 367,
    avg_round: 31904,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Bronya',
    char_four: 'Huohuo',
    app_rate: 0.04,
    rank: 367,
    avg_round: 36100,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Clara',
    char_three: 'Herta',
    char_four: 'Robin',
    app_rate: 0.04,
    rank: 367,
    avg_round: 29975,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Herta',
    char_three: 'Sparkle',
    char_four: 'Tingyun',
    app_rate: 0.04,
    rank: 367,
    avg_round: 36520,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Pela',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.04,
    rank: 367,
    avg_round: 36327,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Gallagher',
    app_rate: 0.04,
    rank: 367,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 367,
    avg_round: 36375,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Argenti',
    char_three: 'Herta',
    char_four: 'Luocha',
    app_rate: 0.04,
    rank: 367,
    avg_round: 36450,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 367,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 367,
    avg_round: 36082,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Clara',
    char_three: 'Herta',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 367,
    avg_round: 34634,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Clara',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.04,
    rank: 367,
    avg_round: 37812,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 367,
    avg_round: 36900,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.04,
    rank: 367,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.04,
    rank: 367,
    avg_round: 32256,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.04,
    rank: 367,
    avg_round: 32223,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.04,
    rank: 367,
    avg_round: 38208,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Tingyun',
    char_four: 'Luocha',
    app_rate: 0.04,
    rank: 367,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.04,
    rank: 367,
    avg_round: 33716,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Gallagher',
    app_rate: 0.04,
    rank: 367,
    avg_round: 38617,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Herta',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 367,
    avg_round: 38412,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.04,
    rank: 367,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Luocha',
    app_rate: 0.04,
    rank: 367,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Gepard',
    app_rate: 0.04,
    rank: 367,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Blade',
    char_three: 'Herta',
    char_four: 'ruan-mei',
    app_rate: 0.04,
    rank: 367,
    avg_round: 37925,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 367,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Herta',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.04,
    rank: 367,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Clara',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.04,
    rank: 367,
    avg_round: 38500,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Bailu',
    app_rate: 0.04,
    rank: 367,
    avg_round: 37300,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Himeko',
    char_three: 'Herta',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 367,
    avg_round: 31440,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Pela',
    char_three: 'Tingyun',
    char_four: 'Lynx',
    app_rate: 0.04,
    rank: 367,
    avg_round: 35124,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Herta',
    char_three: 'Bronya',
    char_four: 'Huohuo',
    app_rate: 0.04,
    rank: 367,
    avg_round: 23594,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Lynx',
    app_rate: 0.04,
    rank: 367,
    avg_round: 35438,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Yunli',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 367,
    avg_round: 37925,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.04,
    rank: 367,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.04,
    rank: 367,
    avg_round: 34534,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Asta',
    char_four: 'Huohuo',
    app_rate: 0.04,
    rank: 367,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Robin',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.04,
    rank: 367,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 367,
    avg_round: 37033,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 367,
    avg_round: 38667,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Bronya',
    char_four: 'Robin',
    app_rate: 0.04,
    rank: 367,
    avg_round: 37600,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Blade',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.04,
    rank: 367,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'Lynx',
    app_rate: 0.04,
    rank: 367,
    avg_round: 36500,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.04,
    rank: 367,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'march-7th-swordmaster',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 367,
    avg_round: 37453,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Gepard',
    app_rate: 0.04,
    rank: 367,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'Herta',
    char_four: 'Luocha',
    app_rate: 0.04,
    rank: 367,
    avg_round: 35002,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.04,
    rank: 367,
    avg_round: 32860,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Bronya',
    char_three: 'trailblazer-imaginary',
    char_four: 'ruan-mei',
    app_rate: 0.04,
    rank: 367,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Blade',
    char_three: 'ruan-mei',
    char_four: 'Lynx',
    app_rate: 0.04,
    rank: 367,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Sparkle',
    app_rate: 0.04,
    rank: 367,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.04,
    rank: 367,
    avg_round: 36554,
    star_num: '4'
  },
  {
    char_one: 'Blade',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Lynx',
    app_rate: 0.04,
    rank: 367,
    avg_round: 37364,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Herta',
    char_three: 'Tingyun',
    char_four: 'Lynx',
    app_rate: 0.04,
    rank: 367,
    avg_round: 30554,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 367,
    avg_round: 37000,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 367,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Herta',
    char_three: 'Tingyun',
    char_four: 'Luocha',
    app_rate: 0.04,
    rank: 367,
    avg_round: 36394,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.04,
    rank: 367,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Pela',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 367,
    avg_round: 34417,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Herta',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 367,
    avg_round: 38533,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.04,
    rank: 367,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Lynx',
    app_rate: 0.04,
    rank: 367,
    avg_round: 35725,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.04,
    rank: 367,
    avg_round: 33364,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Herta',
    char_three: 'topaz',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 367,
    avg_round: 35207,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Gallagher',
    app_rate: 0.04,
    rank: 367,
    avg_round: 33089,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.04,
    rank: 367,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 367,
    avg_round: 38561,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 455,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Clara',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 455,
    avg_round: 33371,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Clara',
    char_three: 'Herta',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 455,
    avg_round: 37950,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Argenti',
    char_three: 'ruan-mei',
    char_four: 'Tingyun',
    app_rate: 0.03,
    rank: 455,
    avg_round: 35750,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Yunli',
    char_three: 'Herta',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 455,
    avg_round: 30392,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Hanya',
    char_three: 'Robin',
    char_four: 'Tingyun',
    app_rate: 0.03,
    rank: 455,
    avg_round: 37650,
    star_num: '4'
  },
  {
    char_one: 'Blade',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 455,
    avg_round: 35590,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 455,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Clara',
    char_three: 'ruan-mei',
    char_four: 'Lynx',
    app_rate: 0.03,
    rank: 455,
    avg_round: 32697,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Pela',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 455,
    avg_round: 34218,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'ruan-mei',
    app_rate: 0.03,
    rank: 455,
    avg_round: 32395,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Xueyi',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 455,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 455,
    avg_round: 35999,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 455,
    avg_round: 27913,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 455,
    avg_round: 32643,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 455,
    avg_round: 32276,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 455,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 455,
    avg_round: 30710,
    star_num: '4'
  },
  {
    char_one: 'black-swan',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 455,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Bronya',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 455,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'march-7th',
    app_rate: 0.03,
    rank: 455,
    avg_round: 32697,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 455,
    avg_round: 34433,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Argenti',
    char_three: 'Yunli',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 455,
    avg_round: 35650,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Lynx',
    app_rate: 0.03,
    rank: 455,
    avg_round: 30114,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 455,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 455,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'ruan-mei',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 455,
    avg_round: 28400,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Pela',
    char_four: 'Gepard',
    app_rate: 0.03,
    rank: 455,
    avg_round: 30725,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Pela',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 455,
    avg_round: 32428,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Yunli',
    char_three: 'Herta',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 455,
    avg_round: 36194,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Blade',
    char_three: 'Bronya',
    char_four: 'Robin',
    app_rate: 0.03,
    rank: 455,
    avg_round: 35150,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 455,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Pela',
    char_three: 'Tingyun',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 455,
    avg_round: 30543,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Blade',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 455,
    avg_round: 37731,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 455,
    avg_round: 38917,
    star_num: '4'
  },
  {
    char_one: 'Blade',
    char_two: 'Herta',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 455,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Tingyun',
    app_rate: 0.03,
    rank: 455,
    avg_round: 37358,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 455,
    avg_round: 33444,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 455,
    avg_round: 26316,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 455,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Clara',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 455,
    avg_round: 37850,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 455,
    avg_round: 32133,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'Tingyun',
    app_rate: 0.03,
    rank: 455,
    avg_round: 37194,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Bronya',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 455,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 455,
    avg_round: 31607,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 455,
    avg_round: 38000,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 455,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Pela',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 455,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Yunli',
    char_three: 'ruan-mei',
    char_four: 'Lynx',
    app_rate: 0.03,
    rank: 455,
    avg_round: 36300,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Bailu',
    app_rate: 0.03,
    rank: 455,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Tingyun',
    app_rate: 0.03,
    rank: 455,
    avg_round: 32173,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Bailu',
    app_rate: 0.03,
    rank: 455,
    avg_round: 36550,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Clara',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 455,
    avg_round: 37675,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Asta',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 455,
    avg_round: 36375,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'ruan-mei',
    char_three: 'Tingyun',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 455,
    avg_round: 32272,
    star_num: '4'
  },
  {
    char_one: 'Blade',
    char_two: 'Bronya',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 455,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 455,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 455,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 455,
    avg_round: 36000,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Herta',
    char_three: 'Sparkle',
    char_four: 'Tingyun',
    app_rate: 0.03,
    rank: 455,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Pela',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 455,
    avg_round: 33649,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'topaz',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 455,
    avg_round: 36254,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Herta',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 455,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Herta',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 455,
    avg_round: 28900,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Herta',
    char_three: 'Bronya',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 455,
    avg_round: 33834,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Clara',
    char_three: 'Herta',
    char_four: 'ruan-mei',
    app_rate: 0.03,
    rank: 455,
    avg_round: 35296,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'topaz',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 455,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 455,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 455,
    avg_round: 36207,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'march-7th-swordmaster',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 455,
    avg_round: 38275,
    star_num: '4'
  },
  {
    char_one: 'black-swan',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 455,
    avg_round: 32242,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 455,
    avg_round: 34738,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 455,
    avg_round: 30961,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Bronya',
    char_three: 'Hanya',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 455,
    avg_round: 34650,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Clara',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 455,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Blade',
    char_three: 'Herta',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 455,
    avg_round: 37250,
    star_num: '4'
  },
  {
    char_one: 'Blade',
    char_two: 'Herta',
    char_three: 'Bronya',
    char_four: 'Sparkle',
    app_rate: 0.03,
    rank: 455,
    avg_round: 34210,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Hanya',
    char_three: 'ruan-mei',
    char_four: 'Tingyun',
    app_rate: 0.03,
    rank: 455,
    avg_round: 37449,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Pela',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 455,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Blade',
    char_three: 'Bronya',
    char_four: 'Sparkle',
    app_rate: 0.03,
    rank: 455,
    avg_round: 36774,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'Tingyun',
    app_rate: 0.03,
    rank: 455,
    avg_round: 32690,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Bronya',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 455,
    avg_round: 31355,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'trailblazer-fire',
    app_rate: 0.03,
    rank: 455,
    avg_round: 38325,
    star_num: '4'
  },
  {
    char_one: 'Blade',
    char_two: 'Herta',
    char_three: 'Bronya',
    char_four: 'ruan-mei',
    app_rate: 0.03,
    rank: 455,
    avg_round: 36450,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Yunli',
    char_three: 'Herta',
    char_four: 'ruan-mei',
    app_rate: 0.03,
    rank: 455,
    avg_round: 32839,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Blade',
    char_three: 'Herta',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 455,
    avg_round: 34548,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Yunli',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 455,
    avg_round: 38450,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 455,
    avg_round: 34107,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Hanya',
    char_three: 'Tingyun',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 455,
    avg_round: 36134,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 455,
    avg_round: 38750,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 455,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'topaz',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 455,
    avg_round: 29691,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Sparkle',
    app_rate: 0.03,
    rank: 455,
    avg_round: 26549,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Yunli',
    char_three: 'Herta',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 455,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Clara',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 455,
    avg_round: 32276,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'ruan-mei',
    app_rate: 0.03,
    rank: 455,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Lynx',
    app_rate: 0.03,
    rank: 455,
    avg_round: 38050,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 455,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'silver-wolf',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 455,
    avg_round: 28634,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 455,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Herta',
    char_three: 'Bronya',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 455,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'Gepard',
    app_rate: 0.03,
    rank: 455,
    avg_round: 34150,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 455,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Herta',
    char_three: 'Bronya',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 455,
    avg_round: 35250,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Herta',
    char_three: 'Tingyun',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 455,
    avg_round: 32128,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Robin',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 455,
    avg_round: 32904,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Yunli',
    char_three: 'Herta',
    char_four: 'ruan-mei',
    app_rate: 0.03,
    rank: 455,
    avg_round: 35223,
    star_num: '4'
  },
  {
    char_one: 'Blade',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Sparkle',
    app_rate: 0.03,
    rank: 455,
    avg_round: 36000,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Bronya',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 455,
    avg_round: 37225,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Herta',
    char_three: 'march-7th-swordmaster',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 455,
    avg_round: 30958,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'topaz',
    char_three: 'Tingyun',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 455,
    avg_round: 37135,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 455,
    avg_round: 34956,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 455,
    avg_round: 37675,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 455,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Robin',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 455,
    avg_round: 34913,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Blade',
    char_three: 'Bronya',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 455,
    avg_round: 29550,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Yunli',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 455,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 455,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Blade',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 573,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Blade',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 573,
    avg_round: 32218,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 573,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Argenti',
    char_three: 'Robin',
    char_four: 'Sparkle',
    app_rate: 0.02,
    rank: 573,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Robin',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 573,
    avg_round: 37650,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Bronya',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 573,
    avg_round: 36973,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Argenti',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 573,
    avg_round: 33760,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Clara',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 573,
    avg_round: 33409,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 573,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 573,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 573,
    avg_round: 36050,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 573,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Guinaifen',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 573,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Pela',
    char_three: 'Tingyun',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 573,
    avg_round: 33572,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Pela',
    char_three: 'ruan-mei',
    char_four: 'Tingyun',
    app_rate: 0.02,
    rank: 573,
    avg_round: 29992,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'silver-wolf',
    char_four: 'Gepard',
    app_rate: 0.02,
    rank: 573,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 573,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 573,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Hanya',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 573,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Gepard',
    app_rate: 0.02,
    rank: 573,
    avg_round: 36100,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 573,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Blade',
    char_two: 'Herta',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 573,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 573,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Argenti',
    char_three: 'Bronya',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 573,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Yunli',
    char_three: 'Clara',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 573,
    avg_round: 29774,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 573,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Pela',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 573,
    avg_round: 33176,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Blade',
    char_three: 'Herta',
    char_four: 'Sparkle',
    app_rate: 0.02,
    rank: 573,
    avg_round: 35985,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 573,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 573,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Robin',
    char_three: 'ruan-mei',
    char_four: 'Sparkle',
    app_rate: 0.02,
    rank: 573,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Robin',
    char_three: 'ruan-mei',
    char_four: 'Tingyun',
    app_rate: 0.02,
    rank: 573,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Blade',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 573,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Herta',
    char_three: 'Pela',
    char_four: 'trailblazer-fire',
    app_rate: 0.02,
    rank: 573,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Blade',
    char_three: 'Bronya',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 573,
    avg_round: 28435,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Pela',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 573,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Clara',
    char_three: 'Blade',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 573,
    avg_round: 28302,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Clara',
    char_three: 'Tingyun',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 573,
    avg_round: 31521,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 573,
    avg_round: 27252,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'Tingyun',
    app_rate: 0.02,
    rank: 573,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Yunli',
    char_three: 'Tingyun',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 573,
    avg_round: 37850,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Asta',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 573,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Argenti',
    char_three: 'Robin',
    char_four: 'Bailu',
    app_rate: 0.02,
    rank: 573,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Herta',
    char_three: 'Pela',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 573,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Natasha',
    app_rate: 0.02,
    rank: 573,
    avg_round: 31500,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 573,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Clara',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 573,
    avg_round: 34350,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Herta',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 573,
    avg_round: 35047,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 573,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Jingliu',
    char_three: 'Blade',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 573,
    avg_round: 29100,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Yunli',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 573,
    avg_round: 31779,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Tingyun',
    app_rate: 0.02,
    rank: 573,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 573,
    avg_round: 27756,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Blade',
    char_three: 'Herta',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 573,
    avg_round: 36844,
    star_num: '4'
  },
  {
    char_one: 'Blade',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 573,
    avg_round: 30930,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Tingyun',
    app_rate: 0.02,
    rank: 573,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Clara',
    char_three: 'Bronya',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 573,
    avg_round: 33019,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Sparkle',
    char_four: 'Lynx',
    app_rate: 0.02,
    rank: 573,
    avg_round: 34850,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Herta',
    char_three: 'topaz',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 573,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 573,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'black-swan',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 573,
    avg_round: 30317,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 573,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Bronya',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 573,
    avg_round: 35192,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 573,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Clara',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 573,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Jiaoqiu',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 573,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 573,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'march-7th-swordmaster',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 573,
    avg_round: 29800,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Asta',
    char_three: 'Sparkle',
    char_four: 'Tingyun',
    app_rate: 0.02,
    rank: 573,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'ruan-mei',
    char_three: 'Tingyun',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 573,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 573,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Clara',
    char_three: 'topaz',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 573,
    avg_round: 31334,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Yunli',
    char_three: 'Clara',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 573,
    avg_round: 36675,
    star_num: '4'
  },
  {
    char_one: 'Blade',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 573,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Lynx',
    app_rate: 0.02,
    rank: 573,
    avg_round: 37000,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Yunli',
    char_three: 'Herta',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 573,
    avg_round: 37500,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 573,
    avg_round: 32203,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'topaz',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 573,
    avg_round: 32874,
    star_num: '4'
  },
  {
    char_one: 'Blade',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 573,
    avg_round: 36450,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Clara',
    char_three: 'trailblazer-imaginary',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 573,
    avg_round: 28017,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Bronya',
    char_three: 'Tingyun',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 573,
    avg_round: 36000,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Blade',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 573,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Blade',
    char_two: 'Herta',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 573,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Clara',
    char_three: 'Bronya',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 573,
    avg_round: 24611,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'ruan-mei',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 573,
    avg_round: 32372,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 573,
    avg_round: 34761,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 573,
    avg_round: 30372,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Bailu',
    app_rate: 0.02,
    rank: 573,
    avg_round: 36300,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Clara',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 573,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 573,
    avg_round: 34200,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 573,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'black-swan',
    char_three: 'Herta',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 573,
    avg_round: 31180,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 573,
    avg_round: 27785,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 573,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Sparkle',
    app_rate: 0.02,
    rank: 573,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Robin',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 573,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Clara',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 573,
    avg_round: 34850,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'Pela',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 573,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Yunli',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 573,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Clara',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 573,
    avg_round: 35758,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 573,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Argenti',
    char_three: 'Bronya',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 573,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 573,
    avg_round: 36847,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Blade',
    char_three: 'Robin',
    char_four: 'Lynx',
    app_rate: 0.02,
    rank: 573,
    avg_round: 38400,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Pela',
    char_three: 'Robin',
    char_four: 'Bailu',
    app_rate: 0.02,
    rank: 573,
    avg_round: 33586,
    star_num: '4'
  },
  {
    char_one: 'Blade',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Lynx',
    app_rate: 0.02,
    rank: 573,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 573,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Yunli',
    char_three: 'Blade',
    char_four: 'Lynx',
    app_rate: 0.02,
    rank: 573,
    avg_round: 35700,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 573,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Pela',
    char_three: 'Robin',
    char_four: 'Tingyun',
    app_rate: 0.02,
    rank: 573,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Clara',
    char_three: 'Herta',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 573,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 573,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Pela',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 573,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Blade',
    char_three: 'Herta',
    char_four: 'Robin',
    app_rate: 0.02,
    rank: 573,
    avg_round: 33632,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Blade',
    char_three: 'Robin',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 573,
    avg_round: 36050,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Gepard',
    app_rate: 0.02,
    rank: 573,
    avg_round: 38275,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Lynx',
    app_rate: 0.02,
    rank: 573,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Robin',
    char_three: 'ruan-mei',
    char_four: 'Tingyun',
    app_rate: 0.02,
    rank: 573,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 573,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 573,
    avg_round: 35100,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 573,
    avg_round: 34064,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Herta',
    char_three: 'Pela',
    char_four: 'Sparkle',
    app_rate: 0.02,
    rank: 573,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Argenti',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 573,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Blade',
    char_three: 'Bronya',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 573,
    avg_round: 27886,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 573,
    avg_round: 38225,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Yunli',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 573,
    avg_round: 36368,
    star_num: '4'
  },
  {
    char_one: 'Xueyi',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 573,
    avg_round: 33082,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Xueyi',
    char_three: 'Herta',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 573,
    avg_round: 28928,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Pela',
    char_three: 'Tingyun',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 573,
    avg_round: 30242,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Pela',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 573,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Jiaoqiu',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 573,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 573,
    avg_round: 29539,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 573,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Xueyi',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 573,
    avg_round: 31451,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 573,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Bronya',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 573,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Clara',
    char_three: 'march-7th-swordmaster',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 573,
    avg_round: 34718,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Clara',
    char_three: 'Herta',
    char_four: 'Sparkle',
    app_rate: 0.02,
    rank: 573,
    avg_round: 29262,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Herta',
    char_three: 'Bronya',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 573,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Pela',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 573,
    avg_round: 34750,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Clara',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 573,
    avg_round: 26189,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Clara',
    char_three: 'Herta',
    char_four: 'Robin',
    app_rate: 0.02,
    rank: 573,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 573,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Natasha',
    app_rate: 0.02,
    rank: 573,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Tingyun',
    app_rate: 0.02,
    rank: 573,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'Sparkle',
    app_rate: 0.02,
    rank: 573,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Herta',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 573,
    avg_round: 34650,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Yunli',
    char_three: 'Tingyun',
    char_four: 'Lynx',
    app_rate: 0.02,
    rank: 573,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Pela',
    char_three: 'Robin',
    char_four: 'Sparkle',
    app_rate: 0.02,
    rank: 573,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Clara',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 573,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Herta',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 573,
    avg_round: 36550,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 573,
    avg_round: 34588,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Himeko',
    char_three: 'Herta',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 573,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Clara',
    char_three: 'Herta',
    char_four: 'Robin',
    app_rate: 0.02,
    rank: 573,
    avg_round: 36820,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Blade',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 573,
    avg_round: 27748,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Xueyi',
    char_three: 'Herta',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 573,
    avg_round: 30315,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Herta',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 573,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Clara',
    char_three: 'Blade',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 573,
    avg_round: 30583,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Bronya',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 573,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 573,
    avg_round: 34255,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 573,
    avg_round: 28275,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Clara',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 573,
    avg_round: 32082,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Herta',
    char_three: 'Tingyun',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 573,
    avg_round: 37600,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Tingyun',
    app_rate: 0.02,
    rank: 573,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'topaz',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 573,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 573,
    avg_round: 30375,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 573,
    avg_round: 36746,
    star_num: '4'
  },
  {
    char_one: 'Blade',
    char_two: 'Herta',
    char_three: 'Pela',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 573,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'Herta',
    char_four: 'Lynx',
    app_rate: 0.02,
    rank: 573,
    avg_round: 35100,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 573,
    avg_round: 30484,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Argenti',
    char_three: 'Sparkle',
    char_four: 'Lynx',
    app_rate: 0.02,
    rank: 573,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'march-7th',
    app_rate: 0.02,
    rank: 573,
    avg_round: 31688,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 573,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Pela',
    char_three: 'Tingyun',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 573,
    avg_round: 35170,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Blade',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 573,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Bronya',
    char_three: 'Hanya',
    char_four: 'Tingyun',
    app_rate: 0.02,
    rank: 573,
    avg_round: 33414,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Clara',
    char_three: 'Herta',
    char_four: 'Lynx',
    app_rate: 0.02,
    rank: 573,
    avg_round: 28899,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 573,
    avg_round: 33780,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 573,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 573,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'Tingyun',
    app_rate: 0.02,
    rank: 573,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Herta',
    char_three: 'Bronya',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 573,
    avg_round: 31344,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Clara',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 573,
    avg_round: 31927,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Clara',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 573,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 573,
    avg_round: 38500,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Bailu',
    app_rate: 0.02,
    rank: 573,
    avg_round: 31807,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'Pela',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 573,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Pela',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 573,
    avg_round: 36421,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Sparkle',
    app_rate: 0.02,
    rank: 573,
    avg_round: 34372,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 573,
    avg_round: 28570,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Herta',
    char_three: 'march-7th-swordmaster',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 573,
    avg_round: 31154,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'ruan-mei',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 573,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Pela',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 573,
    avg_round: 33032,
    star_num: '4'
  },
  {
    char_one: 'Blade',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Sparkle',
    app_rate: 0.02,
    rank: 573,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Hanya',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 573,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'march-7th-swordmaster',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 573,
    avg_round: 36500,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Yunli',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 573,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 573,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'ruan-mei',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 573,
    avg_round: 27839,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Firefly',
    char_three: 'trailblazer-imaginary',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 573,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 573,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 573,
    avg_round: 32050,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Herta',
    char_three: 'Bronya',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 573,
    avg_round: 35550,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 573,
    avg_round: 36000,
    star_num: '4'
  },
  {
    char_one: 'Blade',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 573,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Blade',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 573,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'Gepard',
    app_rate: 0.02,
    rank: 573,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Clara',
    char_three: 'Sparkle',
    char_four: 'Lynx',
    app_rate: 0.02,
    rank: 573,
    avg_round: 33708,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Herta',
    char_three: 'march-7th-swordmaster',
    char_four: 'Lynx',
    app_rate: 0.02,
    rank: 573,
    avg_round: 28072,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'ruan-mei',
    char_four: 'Tingyun',
    app_rate: 0.02,
    rank: 573,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 573,
    avg_round: 28788,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Pela',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 573,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Yunli',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 573,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Herta',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 573,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Yunli',
    char_three: 'Herta',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 573,
    avg_round: 35111,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 573,
    avg_round: 38200,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 573,
    avg_round: 32007,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 573,
    avg_round: 36200,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Asta',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 573,
    avg_round: 36625,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Pela',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 573,
    avg_round: 25882,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 573,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Herta',
    char_three: 'Hanya',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 573,
    avg_round: 36400,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 573,
    avg_round: 35625,
    star_num: '4'
  },
  {
    char_one: 'Xueyi',
    char_two: 'Clara',
    char_three: 'Herta',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 573,
    avg_round: 28203,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Pela',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 573,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 573,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 573,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Xueyi',
    char_two: 'Herta',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 573,
    avg_round: 30908,
    star_num: '4'
  },
  {
    char_one: 'Xueyi',
    char_two: 'Clara',
    char_three: 'Herta',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 573,
    avg_round: 26212,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Blade',
    char_three: 'ruan-mei',
    char_four: 'Lynx',
    app_rate: 0.02,
    rank: 573,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Clara',
    char_three: 'Herta',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 573,
    avg_round: 30792,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'topaz',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 573,
    avg_round: 29364,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Herta',
    char_three: 'march-7th-swordmaster',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 573,
    avg_round: 32696,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Herta',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 573,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Blade',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 573,
    avg_round: 32343,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Argenti',
    char_three: 'Herta',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 573,
    avg_round: 35625,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 573,
    avg_round: 38500,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 573,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Yunli',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 573,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Clara',
    char_three: 'ruan-mei',
    char_four: 'Sparkle',
    app_rate: 0.02,
    rank: 573,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'ruan-mei',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 573,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Clara',
    char_three: 'ruan-mei',
    char_four: 'Lynx',
    app_rate: 0.02,
    rank: 573,
    avg_round: 35475,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Herta',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 573,
    avg_round: 32347,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Clara',
    char_three: 'Tingyun',
    char_four: 'Lynx',
    app_rate: 0.02,
    rank: 573,
    avg_round: 30002,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 573,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Herta',
    char_three: 'Sparkle',
    char_four: 'Tingyun',
    app_rate: 0.02,
    rank: 573,
    avg_round: 32456,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Xueyi',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 573,
    avg_round: 33026,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Yunli',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 573,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Bronya',
    char_three: 'Tingyun',
    char_four: 'Lynx',
    app_rate: 0.02,
    rank: 573,
    avg_round: 36200,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Blade',
    char_three: 'Bronya',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 573,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Herta',
    char_three: 'Pela',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 573,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Pela',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 573,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 573,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Bronya',
    char_three: 'Sparkle',
    char_four: 'Tingyun',
    app_rate: 0.02,
    rank: 573,
    avg_round: 29750,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'ruan-mei',
    char_three: 'Tingyun',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 573,
    avg_round: 34013,
    star_num: '4'
  }
];
