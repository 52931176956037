export const PFCharacterData = [
  {
    char: 'acheron',
    name: 'Acheron',
    element: 'lightning',
    current_app_rate: 58.48,
    prev_app_rate: 44.99,
    current_avg_score: 31124,
    prev_avg_score: 28579,
    rarity: 'Limited 5*'
  },
  {
    char: 'argenti',
    name: 'Argenti',
    element: 'physical',
    current_app_rate: 17.12,
    prev_app_rate: 14.51,
    current_avg_score: 39622,
    prev_avg_score: 33955,
    rarity: 'Limited 5*'
  },
  {
    char: 'arlan',
    name: 'Arlan',
    element: 'lightning',
    current_app_rate: 0.01,
    prev_app_rate: 0,
    current_avg_score: 0,
    prev_avg_score: 0,
    rarity: '4*'
  },
  {
    char: 'asta',
    name: 'Asta',
    element: 'fire',
    current_app_rate: 1.65,
    prev_app_rate: 3.32,
    current_avg_score: 28163,
    prev_avg_score: 32641,
    rarity: '4*'
  },
  {
    char: 'aventurine',
    name: 'Aventurine',
    element: 'imaginary',
    current_app_rate: 37.53,
    prev_app_rate: 43.51,
    current_avg_score: 33524,
    prev_avg_score: 31947,
    rarity: 'Limited 5*'
  },
  {
    char: 'bailu',
    name: 'Bailu',
    element: 'lightning',
    current_app_rate: 1.35,
    prev_app_rate: 1.2,
    current_avg_score: 32476,
    prev_avg_score: 28999,
    rarity: 'Standard 5*'
  },
  {
    char: 'black-swan',
    name: 'Black Swan',
    element: 'wind',
    current_app_rate: 29.83,
    prev_app_rate: 28.69,
    current_avg_score: 30831,
    prev_avg_score: 31389,
    rarity: 'Limited 5*'
  },
  {
    char: 'blade',
    name: 'Blade',
    element: 'wind',
    current_app_rate: 4.85,
    prev_app_rate: 3.24,
    current_avg_score: 34843,
    prev_avg_score: 29079,
    rarity: 'Limited 5*'
  },
  {
    char: 'boothill',
    name: 'Boothill',
    element: 'physical',
    current_app_rate: 0.28,
    prev_app_rate: 0.41,
    current_avg_score: 0,
    prev_avg_score: 0,
    rarity: 'Limited 5*'
  },
  {
    char: 'bronya',
    name: 'Bronya',
    element: 'wind',
    current_app_rate: 9.6,
    prev_app_rate: 11.52,
    current_avg_score: 36340,
    prev_avg_score: 30489,
    rarity: 'Standard 5*'
  },
  {
    char: 'clara',
    name: 'Clara',
    element: 'physical',
    current_app_rate: 21.46,
    prev_app_rate: 21.57,
    current_avg_score: 36468,
    prev_avg_score: 30105,
    rarity: 'Standard 5*'
  },
  {
    char: 'dan-heng',
    name: 'Dan Heng',
    element: 'wind',
    current_app_rate: 0.01,
    prev_app_rate: 0.01,
    current_avg_score: 0,
    prev_avg_score: 0,
    rarity: '4*'
  },
  {
    char: 'dr-ratio',
    name: 'Dr. Ratio',
    element: 'imaginary',
    current_app_rate: 0.45,
    prev_app_rate: 5.45,
    current_avg_score: 24292,
    prev_avg_score: 28298,
    rarity: 'Limited 5*'
  },
  {
    char: 'trailblazer-fire',
    name: 'Fire Trailblazer',
    element: 'fire',
    current_app_rate: 0.67,
    prev_app_rate: 0.57,
    current_avg_score: 30479,
    prev_avg_score: 33391,
    rarity: '4*'
  },
  {
    char: 'firefly',
    name: 'Firefly',
    element: 'fire',
    current_app_rate: 20.8,
    prev_app_rate: 30.49,
    current_avg_score: 25716,
    prev_avg_score: 30036,
    rarity: 'Limited 5*'
  },
  {
    char: 'fu-xuan',
    name: 'Fu Xuan',
    element: 'quantum',
    current_app_rate: 45.58,
    prev_app_rate: 43.16,
    current_avg_score: 34491,
    prev_avg_score: 32397,
    rarity: 'Limited 5*'
  },
  {
    char: 'gallagher',
    name: 'Gallagher',
    element: 'fire',
    current_app_rate: 41.63,
    prev_app_rate: 39.47,
    current_avg_score: 29354,
    prev_avg_score: 33702,
    rarity: '4*'
  },
  {
    char: 'gepard',
    name: 'Gepard',
    element: 'ice',
    current_app_rate: 3.64,
    prev_app_rate: 3.41,
    current_avg_score: 30697,
    prev_avg_score: 26687,
    rarity: 'Standard 5*'
  },
  {
    char: 'guinaifen',
    name: 'Guinaifen',
    element: 'fire',
    current_app_rate: 3.95,
    prev_app_rate: 0.74,
    current_avg_score: 26806,
    prev_avg_score: 0,
    rarity: '4*'
  },
  {
    char: 'hanya',
    name: 'Hanya',
    element: 'physical',
    current_app_rate: 1.17,
    prev_app_rate: 0.98,
    current_avg_score: 39241,
    prev_avg_score: 31353,
    rarity: '4*'
  },
  {
    char: 'herta',
    name: 'Herta',
    element: 'ice',
    current_app_rate: 56.22,
    prev_app_rate: 78.06,
    current_avg_score: 36288,
    prev_avg_score: 35136,
    rarity: '4*'
  },
  {
    char: 'himeko',
    name: 'Himeko',
    element: 'fire',
    current_app_rate: 38.7,
    prev_app_rate: 72.61,
    current_avg_score: 29626,
    prev_avg_score: 34699,
    rarity: 'Standard 5*'
  },
  {
    char: 'hook',
    name: 'Hook',
    element: 'fire',
    current_app_rate: 0,
    prev_app_rate: 0,
    current_avg_score: 0,
    prev_avg_score: 0,
    rarity: '4*'
  },
  {
    char: 'huohuo',
    name: 'Huohuo',
    element: 'wind',
    current_app_rate: 33.55,
    prev_app_rate: 20.04,
    current_avg_score: 37905,
    prev_avg_score: 32559,
    rarity: 'Limited 5*'
  },
  {
    char: 'trailblazer-imaginary',
    name: 'Imaginary Trailblazer',
    element: 'imaginary',
    current_app_rate: 17.17,
    prev_app_rate: 23.73,
    current_avg_score: 24981,
    prev_avg_score: 28050,
    rarity: '4*'
  },
  {
    char: 'imbibitor-lunae',
    name: 'Imbibitor Lunae',
    element: 'imaginary',
    current_app_rate: 1.67,
    prev_app_rate: 4.11,
    current_avg_score: 0,
    prev_avg_score: 24078,
    rarity: 'Limited 5*'
  },
  {
    char: 'jade',
    name: 'Jade',
    element: 'quantum',
    current_app_rate: 26.68,
    prev_app_rate: 14.03,
    current_avg_score: 39162,
    prev_avg_score: 39334,
    rarity: 'Limited 5*'
  },
  {
    char: 'jiaoqiu',
    name: 'Jiaoqiu',
    element: 'fire',
    current_app_rate: 12.95,
    prev_app_rate: 0,
    current_avg_score: 33894,
    prev_avg_score: 0,
    rarity: 'Limited 5*'
  },
  {
    char: 'jing-yuan',
    name: 'Jing Yuan',
    element: 'lightning',
    current_app_rate: 6.33,
    prev_app_rate: 4.12,
    current_avg_score: 25462,
    prev_avg_score: 27826,
    rarity: 'Limited 5*'
  },
  {
    char: 'jingliu',
    name: 'Jingliu',
    element: 'ice',
    current_app_rate: 1.63,
    prev_app_rate: 1.86,
    current_avg_score: 30372,
    prev_avg_score: 28050,
    rarity: 'Limited 5*'
  },
  {
    char: 'kafka',
    name: 'Kafka',
    element: 'lightning',
    current_app_rate: 35.25,
    prev_app_rate: 29.23,
    current_avg_score: 30873,
    prev_avg_score: 31378,
    rarity: 'Limited 5*'
  },
  {
    char: 'luka',
    name: 'Luka',
    element: 'physical',
    current_app_rate: 0,
    prev_app_rate: 0.04,
    current_avg_score: 0,
    prev_avg_score: 0,
    rarity: '4*'
  },
  {
    char: 'luocha',
    name: 'Luocha',
    element: 'imaginary',
    current_app_rate: 16.32,
    prev_app_rate: 12.67,
    current_avg_score: 34578,
    prev_avg_score: 30388,
    rarity: 'Limited 5*'
  },
  {
    char: 'lynx',
    name: 'Lynx',
    element: 'quantum',
    current_app_rate: 3.84,
    prev_app_rate: 1.51,
    current_avg_score: 34434,
    prev_avg_score: 27888,
    rarity: '4*'
  },
  {
    char: 'march-7th',
    name: 'March 7th',
    element: 'ice',
    current_app_rate: 0.41,
    prev_app_rate: 1.44,
    current_avg_score: 33527,
    prev_avg_score: 29881,
    rarity: '4*'
  },
  {
    char: 'march-7th-swordmaster',
    name: 'March 7th - The Hunt',
    element: 'imaginary',
    current_app_rate: 3.35,
    prev_app_rate: 0,
    current_avg_score: 32295,
    prev_avg_score: 0,
    rarity: '4*'
  },
  {
    char: 'misha',
    name: 'Misha',
    element: 'ice',
    current_app_rate: 0.02,
    prev_app_rate: 0.01,
    current_avg_score: 0,
    prev_avg_score: 0,
    rarity: '4*'
  },
  {
    char: 'natasha',
    name: 'Natasha',
    element: 'physical',
    current_app_rate: 0.22,
    prev_app_rate: 0.1,
    current_avg_score: 36881,
    prev_avg_score: 0,
    rarity: '4*'
  },
  {
    char: 'pela',
    name: 'Pela',
    element: 'ice',
    current_app_rate: 24.89,
    prev_app_rate: 18.07,
    current_avg_score: 30186,
    prev_avg_score: 28056,
    rarity: '4*'
  },
  {
    char: 'trailblazer-physical',
    name: 'Physical Trailblazer',
    element: 'physical',
    current_app_rate: 0.02,
    prev_app_rate: 0,
    current_avg_score: 0,
    prev_avg_score: 0,
    rarity: '4*'
  },
  {
    char: 'qingque',
    name: 'Qingque',
    element: 'quantum',
    current_app_rate: 0.28,
    prev_app_rate: 0.27,
    current_avg_score: 29292,
    prev_avg_score: 0,
    rarity: '4*'
  },
  {
    char: 'robin',
    name: 'Robin',
    element: 'physical',
    current_app_rate: 44.32,
    prev_app_rate: 41.59,
    current_avg_score: 38597,
    prev_avg_score: 32911,
    rarity: 'Limited 5*'
  },
  {
    char: 'ruan-mei',
    name: 'Ruan Mei',
    element: 'ice',
    current_app_rate: 65.07,
    prev_app_rate: 86.54,
    current_avg_score: 31126,
    prev_avg_score: 34033,
    rarity: 'Limited 5*'
  },
  {
    char: 'sampo',
    name: 'Sampo',
    element: 'wind',
    current_app_rate: 0.38,
    prev_app_rate: 0.11,
    current_avg_score: 26839,
    prev_avg_score: 0,
    rarity: '4*'
  },
  {
    char: 'seele',
    name: 'Seele',
    element: 'quantum',
    current_app_rate: 3.51,
    prev_app_rate: 2.31,
    current_avg_score: 34549,
    prev_avg_score: 28880,
    rarity: 'Limited 5*'
  },
  {
    char: 'serval',
    name: 'Serval',
    element: 'lightning',
    current_app_rate: 1.98,
    prev_app_rate: 0.29,
    current_avg_score: 27242,
    prev_avg_score: 0,
    rarity: '4*'
  },
  {
    char: 'silver-wolf',
    name: 'Silver Wolf',
    element: 'quantum',
    current_app_rate: 6.43,
    prev_app_rate: 5.41,
    current_avg_score: 26833,
    prev_avg_score: 26086,
    rarity: 'Limited 5*'
  },
  {
    char: 'sparkle',
    name: 'Sparkle',
    element: 'quantum',
    current_app_rate: 38.12,
    prev_app_rate: 39.72,
    current_avg_score: 36361,
    prev_avg_score: 31654,
    rarity: 'Limited 5*'
  },
  {
    char: 'sushang',
    name: 'Sushang',
    element: 'physical',
    current_app_rate: 0.03,
    prev_app_rate: 0.08,
    current_avg_score: 0,
    prev_avg_score: 0,
    rarity: '4*'
  },
  {
    char: 'tingyun',
    name: 'Tingyun',
    element: 'lightning',
    current_app_rate: 30.74,
    prev_app_rate: 24.7,
    current_avg_score: 37309,
    prev_avg_score: 32057,
    rarity: '4*'
  },
  {
    char: 'topaz',
    name: 'Topaz & Numby',
    element: 'fire',
    current_app_rate: 5.79,
    prev_app_rate: 15.28,
    current_avg_score: 29715,
    prev_avg_score: 30985,
    rarity: 'Limited 5*'
  },
  {
    char: 'welt',
    name: 'Welt',
    element: 'imaginary',
    current_app_rate: 0.65,
    prev_app_rate: 1.05,
    current_avg_score: 26016,
    prev_avg_score: 0,
    rarity: 'Standard 5*'
  },
  {
    char: 'xueyi',
    name: 'Xueyi',
    element: 'quantum',
    current_app_rate: 1.15,
    prev_app_rate: 3.61,
    current_avg_score: 31673,
    prev_avg_score: 31865,
    rarity: '4*'
  },
  {
    char: 'yanqing',
    name: 'Yanqing',
    element: 'ice',
    current_app_rate: 0,
    prev_app_rate: 0,
    current_avg_score: 0,
    prev_avg_score: 0,
    rarity: 'Standard 5*'
  },
  {
    char: 'yukong',
    name: 'Yukong',
    element: 'imaginary',
    current_app_rate: 0.12,
    prev_app_rate: 0.14,
    current_avg_score: 0,
    prev_avg_score: 0,
    rarity: '4*'
  },
  {
    char: 'yunli',
    name: 'Yunli',
    element: 'physical',
    current_app_rate: 22,
    prev_app_rate: 0,
    current_avg_score: 38596,
    prev_avg_score: 0,
    rarity: 'Limited 5*'
  }
];
